import { Scrollbar } from "./scrollbar";

export class Controller {
  constructor() {
    this._scrollbars = new Map();
  }

  start() {
    this.init();
    this.attachEventListeners();
  }

  init() {
    this.cleanup();
    this.update();
  }

  attachEventListeners() {
    document.addEventListener("turbo:load", () => { this.init(); })
  }

  cleanup() {
    this._scrollbars
      .forEach((scrollbar, key) => {
        if(!scrollbar.isAttached()) {
          this._scrollbars.delete(key);
          scrollbar.destroy();
        }
      }, this);
  }

  update() {
    const elements = document.querySelectorAll(".page-inner .scrollbar");
    elements.forEach((element) => {
      if(this._scrollbars.has(element)) {
        const scrollbar = this._scrollbars.get(element);
        scrollbar.update();
      } else {
        this._scrollbars.set(element, new Scrollbar(element));
      }
    }, this);
  }
}
