import PerfectScrollbar from "perfect-scrollbar";

export class Scrollbar {
  constructor(element) {
    this._element = element;
    const suppressX = this.getDataAttribute('suppress-x', false);
    const suppressY = this.getDataAttribute('suppress-y', false);
    const wheelPropagation = this.getDataAttribute('wheel-propagation', true);
    this._ps = new PerfectScrollbar(
      this._element,
      {
        suppressScrollX: suppressX,
        suppressScrollY: suppressY,
        wheelPropagation: wheelPropagation
      }
    )
  }

  getDataAttribute(name, defaultValue) {
    const key = `data-scrollbar-${name}`
    const attr = this._element.attributes[key]

    if(attr)
      return attr.value;
    else
      return defaultValue;
  }

  isAttached() {
    return this._element.isConnected;
  }

  update() {
    this._ps.update();
  }

  destroy() {
    this._ps.destroy();
    this._ps = null;
    this._element = null;
  }
}
