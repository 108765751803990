export class Popovers {
  constructor() {
    this._popovers = []
  }

  static start() {
    if(!window.Popovers) {
      window.Popovers = new this();
      window.Popovers.start();
    }
  }

  start() {
    this.init();
    this.attachEventListeners();
  }

  init() {
    this.cleanup();
    this.create();
  }

  attachEventListeners() {
    document.addEventListener("turbo:load", () => this.init());
  }

  cleanup() {
    this._popovers.forEach((popover) => {
      $(popover).popover('dispose');
    })
  }

  create() {
    const elements = document.querySelectorAll(
      '[data-toggle="popover"]'
    )
    for(const element of elements) {
      $(element).popover()

      this._popovers.push(element)
    }
  }
}
