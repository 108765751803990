import { Tab } from "./tab";

export class TabManager {
  constructor() {
    this._tabs = new Map();
    this._linksSelector = '#page-sidebar-navigation a[data-toggle="tab"]';
  }

  start() {
    this.init();
    this.attachEventListeners();
  }

  init() {
    this.cleanup();
    this.update();
  }

  attachEventListeners() {
    document.addEventListener("turbo:load", () => { this.init(); })
    $(document).on("shown.bs.tab", this._linksSelector, (e) =>{
      const link = e.target;
      const key = e.target.attributes["href"].value;
      const tab = this._tabs.get(key);
      const lazyLoadImages = (link.dataset.lazyLoadImages == "true");
      if(lazyLoadImages)
        tab.lazyLoadImages();
      tab.scroll();
    })
  }

  cleanup() {
    this._tabs.forEach((tab, key) => {
      if (!tab.isAttached()) {
        this._tabs.delete(key);
        tab.destroy();
      }
    }, this)
  }

  update() {
    const links = document.querySelectorAll(this._linksSelector);

    links.forEach((link) => {
      const key = link.attributes['href'].value;
      const active = link.classList.contains('active');
      if(!this._tabs.has(key)){
        const tab =  new Tab(key)
        this._tabs.set(key, tab);
        tab.setActive(active);
        tab.scroll();
      } else {
        const tab = this._tabs.get(key);
        tab.setActive(active);
        tab.scroll();
      }
    }, this );
  }

  scroll(key) {
    if(!this._tabs.has(key))
      return;

    const tab = this._tabs.get(key);
    tab.scroll(true);
  }
}
