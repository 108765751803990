"use strict";
import { BrowserDetector } from "./browser-detector"
import { OsDetector } from "./os-detector"
import { DatePickers } from "./date-pickers"
import { Reactive } from "./reactive"
import { Form } from "./form"
import { SelectInputs } from "./select-inputs"
import { MaskedInputs } from "./masked-inputs"
import { MomentAgo } from "./moment-ago"
import { Geolocation } from "./geolocation"
import { DOMUtils } from "./dom-utils"
import { ActiveTabs } from "./active-tabs"
import { AutoRefresh } from "./auto-refresh"
import { TurbolinksPermanentValues } from "./turbolinks-permanent-values"
import { CurrentLink } from "./current-link"
import { DisableElements } from "./disable-elements"
import { DeepLink } from "./deep-link"
import { Utils } from "./utils"
import { ClipboardUtils } from "./clipboard-utils"
import Sidemenu from "./sidemenu"
import Sidebar from "./sidebar"
import DocumentViewer from "./document-viewer"
import DocumentUploader from "./document-uploader"
import PageScrollbars from "./page-scrollbars"
import SidebarComments from "./sidebar-comments"
import { NestedDropdowns } from "./nested_dropdowns"
import { Tooltips } from "./tooltips"
import { Popovers } from "./popovers"

class Theme {
  static start() {
    if(!window.looper) {
      window.looper = new Theme()
      window.looper.start()
    }

  }
  start() {
    $(document).ready(()=> {
      Popper.Defaults.modifiers.computeStyle.gpuAcceleration = false
      this.attachEventListeners()
    })
  }

  attachEventListeners() {
    this.fileInputBehavior()
    this.tableDropDownMenu()
    this.hamburger()
    this.autofocusInputBehaviour()
  }

  /**
   * Add text value to our custom file input
   */
  fileInputBehavior () {
    // copy label text to data label which we'll use later
    $('.custom-file > .custom-file-label').each(function () {
      const label = $(this).text()
      $(this).data('label', label)
    })

    // update label text with current input value
    $(document).on('change', '.custom-file > .custom-file-input', function () {
      const files = this.files
      const $fileLabel = $(this).next('.custom-file-label')
      // use when no file chosen
      const $originLabel = $fileLabel.data('label')

      // truncate text when user chose more than 2 files
      $fileLabel.text(files.length + ' files selected')

      if (files.length <= 2) {
        let fileNames = []
        for (let i = 0; i < files.length; i++) {
          fileNames.push(files[i].name)
        }
        $fileLabel.text(fileNames.join(', '))
      }

      // reset label text if no file chosen
      if (!files.length) {
        $fileLabel.text($originLabel)
      }
    })
  }

  tableDropDownMenu() {
    if(!BrowserDetector.isMobile) {
      $('.table-responsive')
        .off('show.bs.dropdown')
        .on('show.bs.dropdown', function () {
          $('.table-responsive').css( "overflow", "inherit" );
        });

      $('.table-responsive')
        .off('hide.bs.dropdown')
        .on('hide.bs.dropdown', function () {
          $('.table-responsive').css( "overflow", "auto" );
        })
    }
  }

  autofocusInputBehaviour () {
    $(document).on('shown.bs.modal shown.bs.dropdown', '.modal, .dropdown', e => {
      const $modal = $(e.target)

      $modal
        .find('input[autofocus]:first, input[data-autofocus="true"]:first')
        .focus()
    })
  }

  /**
   * Handle hamburger .active state
   */
  hamburger () {
    $(document).on('click', '.hamburger-toggle', function () {
      $(this).toggleClass('active')
    })
  }
}

/**
 * Attach hook to clear all modals before visiting another page
 */
$(document).on("turbo:before-visit", function(evt) {
  $('.modal').modal('hide')
  $('body').removeClass('modal-open')
  $('.modal-backdrop').remove()
});

$(document).on("hidden.bs.modal", function(evt) {
  evt.target.remove()
});
/**
 * Attach hook to clear all modals before visiting another page
 */
$(document).on("turbo:before-cache", function(evt) {
  $('.app-aside').removeClass('show')
  $('.aside-backdrop').removeClass('show')
  $('[data-toggle="aside"]').removeClass('active')
});
/**
 *
 * Initialize Theme as Looper,
 * we can use it as global variable.
 * @example
 * Looper.setSkin('dark')
 */

Theme.start()
SelectInputs.start()
MaskedInputs.init()
MomentAgo.start()
Geolocation.init()
ActiveTabs.init()
TurbolinksPermanentValues.init()
DatePickers.start()
Sidemenu.start()
Sidebar.start()
CurrentLink.start([
  "page-current-pending-notifications-list"
])
DisableElements.start()
DeepLink.start()
DocumentViewer.start()
DocumentUploader.start()
window.DOMUtils = DOMUtils
PageScrollbars.start()
SidebarComments.start()
ClipboardUtils.start()
Form.start()
Reactive.start()
NestedDropdowns.start()
Tooltips.start()
Popovers.start()
AutoRefresh.init()
