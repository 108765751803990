export class Tooltips {
  constructor() {
    this._tooltips = []
  }

  static start() {
    if(!window.Tooltips) {
      window.Tooltips = new this();
      window.Tooltips.start();
    }
  }

  start() {
    this.init();
    this.attachEventListeners();
  }

  init() {
    this.cleanup();
    this.create();
  }

  attachEventListeners() {
    document.addEventListener("turbo:load", () => this.init());
  }

  cleanup() {
    this._tooltips.forEach((tooltip) => {
      $(tooltip).tooltip('dispose');
    })
  }

  create() {
    const elements = document.querySelectorAll(
      '[data-toggle="tooltip"]'
    )
    for(const element of elements) {
      $(element).tooltip()

      this._tooltips.push(element)
    }
  }
}
